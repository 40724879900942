<!--
@description: user个人中心
@author: fc
@createtime: 2021年11月22日 16:24:24 
-->
<template>
  <div class="userinfo-container">
    <div class="userinfo-back"></div>
    <div class="line-ele">
      <img class="user-avatar" :src="img" />
      <div class="btn-position">
        <el-button
          class="edit-userinfo-btn"
          @click="showUserInfoEditPanel"
          icon="el-icon-edit"
          >Edit profile</el-button
        >
      </div>
    </div>
    <div class="user-description" v-show="!isShowUserInfoEditPanel">
      <h2 class="desc-name">{{ username }}</h2>
      <div>
        <address>{{ email }}</address>
        <i class="el-icon-copy-document"></i>
      </div>

      <p class="self-introduction">
        {{ introduction }}
      </p>
      <el-button type="text" @click="createNewEvent" class="create-event-btn">{{
        $t("lang.createNewInvite")
      }}</el-button>
    </div>
    <el-form
      :model="profileForm"
      label-position="left"
      label-width="100px"
      ref="profileForm"
      class="user-description"
      v-show="isShowUserInfoEditPanel"
    >
      <el-form-item
        label="头像"
        prop="avatar"
        :rules="[
          { required: true, message: $t('lang.chooseAvatar'), trigger: 'blur' },
        ]"
      >
        <img :src="profileForm.avatar" class="selected-avatar" />
        <el-select
          popper-class="avatar-type"
          v-model="profileForm.avatar"
          :placeholder="$t('lang.chooseAvatar')"
          :loading="loading"
        >
          <el-option
            v-for="item in avatarOptions"
            :key="item.value"
            :value="item.avatar"
          >
            <div class="select-wrap">
              <img :src="item.avatar" class="select-avatar" />
            </div>
          </el-option>
        </el-select>
        <el-upload
          class="avatar-uploader"
          :action="''"
          :show-file-list="true"
          :limit="1"
          :auto-upload="false"
          accept="image/jpeg,image/jpg,image/png"
          :on-change="handleAvatarChange"
          :http-request="handleAvatarChange"
        >
          <el-button size="small" type="primary">{{
            $t("lang.upload")
          }}</el-button>
          <div slot="tip" class="el-upload__tip">
            {{ $t("lang.uploadLimit") }}
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
        :label="$t('lang.nickName')"
        prop="username"
        :rules="[
          { required: true, message: $t('lang.nickName'), trigger: 'blur' },
        ]"
      >
        <el-input v-model="profileForm.username"></el-input>
      </el-form-item>
      <el-form-item :label="$t('lang.contact')" prop="contact">
        <el-input v-model="profileForm.contact.contactDetail">
          <el-select
            v-model="profileForm.contact.contactType"
            :placeholder="$t('lang.type')"
            slot="prepend"
          >
            <el-option label="Discord" value="discord"> </el-option>
            <el-option label="Email" value="email"> </el-option>
            <el-option label="Wechat" value="wechat"> </el-option>
            <el-option label="Others" value="others"> </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('lang.description')" prop="introduction">
        <el-input v-model="profileForm.introduction"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="text"
          @click="handleCompleteEditProfile"
          class="create-event-btn"
          >{{ $t("lang.completed") }}</el-button
        >
      </el-form-item>
    </el-form>
    <div
      class="list-container"
      v-loading="loading"
      :element-loading-text="$t('lang.loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 250, 233, 0.85)"
    >
      <div class="list-header">
        <el-badge
          :hidden="true"
          :value="eventsNotRead"
          class="event-btn"
          :style="isEvent ? 'border-bottom:1px solid #000' : ''"
          @click.native="handleIsEventChange(true)"
          >{{ $t("lang.events") }}</el-badge
        >
        <el-badge
          :value="messagesNotRead"
          class="messages-btn"
          :style="!isEvent ? 'border-bottom:1px solid #000' : ''"
          @click.native="handleIsEventChange(false)"
          >{{ $t("lang.messages") }}</el-badge
        >
      </div>
      <div
        class="list-content"
        v-loading="loading"
        :element-loading-text="$t('lang.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 250, 233, 0.85)"
      >
        <div class="events-container" v-show="isEvent">
          <ul class="event-list">
            <li
              class="event-item"
              v-for="event of events"
              :key="event.id"
              @click.stop="initChangeTo(event)"
            >
              <div
                :class="
                  event.status || event.status === 0
                    ? `event-status ${statusClass[event.status]}`
                    : `event-status`
                "
              >
                {{ statusNameMap[event.status] }}
              </div>
              <div class="event-time">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-rili-mian"></use>
                </svg>
                <el-date-picker
                  prefix-icon="el-icon-none"
                  v-model="event.date"
                  type="datetimerange"
                  range-separator="—"
                  :start-placeholder="$t('lang.formHolder[5]')"
                  :end-placeholder="$t('lang.formHolder[6]')"
                  align="right"
                  format="yyyy-MM-dd HH:mm"
                  :disabled="handleShowBtn('disableDate', event)"
                  class="datetime"
                >
                </el-date-picker>
              </div>
              <div class="event-targets">
                <span class="event-postUser">
                  <img :src="event.postUser.avatar" class="postUser-avatar" />
                  <span class="postUser-username"
                    >{{ event.postUser.username }}
                  </span>
                </span>
                <span class="event-action">{{ $t("lang.invites") }}</span>
                <span class="event-accepter">
                  <img
                    :src="event.receiveUser.avatar"
                    class="receiveUser-avatar"
                  />
                  <span class="receiveUser-username"
                    >{{ event.receiveUser.username }}
                  </span>
                </span>
              </div>
              <div class="event-content">{{ event.content }}</div>
              <div class="event-description">{{ event.description }}</div>
              <div class="event-bounty">
                <span class="bounty">{{ event.bounty }}</span
                ><span style="color: #b7b7c2">$coop</span>
              </div>
              <address class="event-address">
                <i class="el-icon-location"></i
                ><span style="color: #b7b7c2">{{ event.address }}</span>
              </address>
              <div
                class="contact-container"
                v-show="handleShowBtn('contact', event) && event.status != 0"
              >
                <span class="contact-type-text">{{
                  $t("lang.receiverContact")
                }}</span>
                <div class="contact-item">
                  <span class="contact-type">
                    <img
                      class="type-icon"
                      :src="typeImageCache[event.receiverContact.contactType]"
                    />
                    <span class="type-name">{{
                      event.receiverContact.contactType
                    }}</span>
                  </span>
                  <el-input
                    disabled
                    class="contact-input"
                    v-model="event.receiverContact.contactDetail"
                    placeholder="Include #Code"
                  ></el-input>
                </div>
              </div>
              <div
                class="contact-container"
                v-show="handleShowBtn('contact', event)"
              >
                <span class="contact-type-text">{{
                  $t("lang.postUserContact")
                }}</span>
                <div class="contact-item">
                  <span class="contact-type">
                    <img
                      class="type-icon"
                      :src="typeImageCache[event.postUserContact.contactType]"
                    />
                    <span class="type-name">{{
                      event.postUserContact.contactType
                    }}</span>
                  </span>
                  <el-input
                    disabled
                    class="contact-input"
                    v-model="event.postUserContact.contactDetail"
                    placeholder="Include #Code"
                  ></el-input>
                </div>
              </div>
              <div class="event-btns">
                <!-- 申领任务 -->
                <div
                  class="claim-container"
                  v-show="handleShowBtn('claim', event)"
                >
                  <el-button
                    class="fc-event-btn greenBg-event-btn"
                    @click.stop="handleClaimEvent(event)"
                    >{{ $t("lang.claim") }}</el-button
                  >
                </div>
                <!-- 取消申领任务 -->
                <div
                  class="cancelClaim-container"
                  v-show="handleShowBtn('cancelclaim', event)"
                >
                  <span
                    style="cursor: pointer"
                    @click="handleCancelClaimEvent(event)"
                    >{{ $t("lang.cancelclaim") }}</span
                  >
                </div>
                <!-- 拒绝 -->
                <div
                  class="deny-container"
                  v-show="
                    handleShowBtn('receiveUser', event) &&
                    handleShowBtn('deny', event)
                  "
                >
                  <span
                    style="cursor: pointer"
                    @click="collectDenyReason(event)"
                    >{{ $t("lang.notInterested") }}</span
                  >
                </div>
                <!-- 接受 只有B能接受-->
                <div
                  class="accept-container"
                  v-show="handleShowBtn('receiveUser', event)"
                >
                  <el-button
                    class="fc-event-btn orangeBg-event-btn"
                    v-show="handleShowBtn('accept', event)"
                    @click.stop="collectAcceptContact(event)"
                    >{{ $t("lang.accept") }}</el-button
                  >
                  <el-button
                    class="fc-event-btn orangeBg-event-btn"
                    v-show="handleShowBtn('deny2', event)"
                    @click.stop="changeToNotInterest(event)"
                    >{{ $t("lang.accept")
                    }}<i
                      style="margin-left: 10px"
                      class="el-icon-arrow-down"
                    ></i
                  ></el-button>
                  <el-button
                    class="fc-event-btn orangeBg-event-btn"
                    v-show="handleShowBtn('accept2', event)"
                    @click.stop="changeToAccept(event)"
                    >{{ $t("lang.notInterested")
                    }}<i
                      style="margin-left: 10px"
                      class="el-icon-arrow-down"
                    ></i>
                  </el-button>
                  <div
                    class="accepte-btn-container"
                    v-show="
                      event.isShowAcceptedSelect ||
                      event.isShowNotInterestSelect
                    "
                  >
                    <el-button
                      v-show="event.isShowAcceptedSelect"
                      class="fc-event-btn orangeBg-event-btn accept-select-btn"
                      @click="collectAcceptContact(event)"
                      >{{ $t("lang.accept") }}</el-button
                    >
                    <el-button
                      v-show="event.isShowNotInterestSelect"
                      class="fc-event-btn orangeBg-event-btn accept-select-btn"
                      @click="collectDenyReason(event)"
                      >{{ $t("lang.notInterested") }}</el-button
                    >
                  </div>
                </div>
                <!-- 取消 -->
                <div
                  class="cancel-container"
                  v-show="handleShowBtn('cancel', event)"
                >
                  <el-button
                    class="fc-event-btn redBg-event-btn cancel-event-btn"
                    @click="handleCancelEvent(event)"
                    >{{ $t("lang.cancel") }}</el-button
                  >
                </div>
                <!-- 编辑 -->
                <div
                  class="edit-container"
                  v-show="handleShowBtn('edit', event)"
                >
                  <span style="cursor: pointer" @click="handleEdit(event)">{{
                    $t("lang.edit")
                  }}</span>
                </div>
                <!-- 删除 -->
                <div
                  class="edit-container"
                  v-show="handleShowBtn('delete', event)"
                >
                  <el-button
                    class="fc-event-btn redBg-event-btn confirm-event-btn"
                    @click="handleDeleteEvent(event)"
                    >{{ $t("lang.delete") }}</el-button
                  >
                </div>
                <!-- A确认B完成 -->
                <div
                  class="complete-container"
                  v-show="handleShowBtn('complete', event)"
                >
                  <el-button
                    class="fc-event-btn greenBg-event-btn complete-event-btn"
                    @click="handleCompleteEvent(event)"
                    >{{ $t("lang.completed") }}</el-button
                  >
                </div>
                <!-- B确认收到了bounty -->
                <div
                  class="complete-container"
                  v-show="handleShowBtn('received', event)"
                >
                  <el-button
                    class="fc-event-btn greenBg-event-btn complete-event-btn"
                    @click="handleEndEvent(event)"
                    >{{ $t("lang.received") }}</el-button
                  >
                </div>
                <!-- 回顾，只读 -->
                <div
                  class="readonly-container"
                  v-show="handleShowBtn('review', event)"
                >
                  <el-button
                    class="fc-event-btn greyBg-event-btn confirm-event-btn"
                    @click="handleReadEvent(event.id)"
                    >{{ $t("lang.review") }}</el-button
                  >
                </div>
              </div>
              <div
                class="event-comment event-claims"
                v-show="handleShowBtn('claimcount', event)"
              >
                <span class="comment-count"
                  >{{ $t("lang.claims") }}({{ event.applicationCount }})</span
                >
              </div>
              <div class="event-comment">
                <span class="comment-count"
                  >{{ $t("lang.comments") }}({{ event.userMessageCount }})</span
                >
                <span class="comment-more"
                  ><span @click="setModuleDialog([true, event.id])">{{
                    $t("lang.viewmore")
                  }}</span></span
                >
              </div>
            </li>
          </ul>
          <el-pagination
            class="event-pagination"
            @size-change="handleEventSizeChange"
            @current-change="handleCurrentEventChange"
            :current-page="currentEventPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="eventPageSize"
            layout="total, sizes, prev, pager, next"
            :total="eventsTotal"
          >
          </el-pagination>
        </div>
        <div class="messages-container" v-show="!isEvent">
          <ul>
            <li
              class="message-item"
              v-for="message of messages"
              :key="message.id"
            >
              <span class="badge-point-wrap">
                <span class="badge-point" v-show="!message.isRead"></span>
              </span>
              <img
                class="creator-avatar"
                v-show="message.fromUser && message.fromUser.avatar"
                :src="message.fromUser.avatar"
              />
              <div class="message-content-container">
                <p>
                  {{ message.content }}
                </p>
                <el-button
                  class="learn-more"
                  @click="editMessageDetail(message)"
                  >{{ $t("lang.learmore") }}</el-button
                >
              </div>
              <span class="message-time">{{
                handleTime(message.createdAt)
              }}</span>
            </li>
          </ul>
          <el-pagination
            class="messages-pagination"
            @size-change="handleActivitySizeChange"
            @current-change="handleCurrentActivityChange"
            :current-page="currentActivityPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="activityPageSize"
            layout="total, sizes, prev, pager, next"
            :total="messagesTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  imagesCache,
  avatarDefault,
  statusClass,
  statusNameMap_zh,
  statusNameMap_en,
} from "@data/avatar.js";
import {
  updataUser,
  getMyMessages,
  getUserAllEvents,
  uploadImg,
  readMessage,
} from "@/api/public.js";
import btnShowMixin from "@/mixins/btnShowMixins.js";
import moduleMethodMixin from "@/mixins/moduleMethod.js";
import timeStageDivide from "@/mixins/timeStageDivide.js";

export default {
  name: "user_wap",
  mixins: [timeStageDivide, btnShowMixin, moduleMethodMixin],
  data() {
    return {
      events: [],
      messages: [],
      isEvent: true,
      eventPageSize: 10,
      activityPageSize: 10,
      activityTotal: 0,
      eventsTotal: 0,
      messagesTotal: 0,
      currentActivityPage: 1,
      currentEventPage: 1,
      profileForm: {
        avatar: null,
        username: null,
        contact: {
          contactDetail: "",
          contactType: "",
        },
        introduction: "",
      },
      isShowUserInfoEditPanel: false, //用户信息编辑
      avatarOptions: [],
      statusClass: statusClass,
      statusNameMap: statusNameMap_zh,
      loading: false,
      fileList: [],
      formData: {},
      upload_img: "",
      eventsNotRead: 0,
      messagesNotRead: 0,
    };
  },
  computed: {
    ...mapGetters(["updataUserEventByEdit"]),
    username() {
      if (this.userInfo && this.userInfo.name) {
        return this.userInfo.name;
      } else {
        return "username";
      }
    },
    email() {
      if (this.userInfo && this.userInfo.email) {
        return this.userInfo.email;
      } else {
        return "email";
      }
    },
    img() {
      if (this.userInfo && this.userInfo.avatar) {
        if (this.userInfo.avatar.length > 2) {
          return this.userInfo.avatar;
        } else {
          return imagesCache[this.userInfo.avatar];
        }
      } else {
        return avatarDefault;
      }
    },
    introduction() {
      if (this.userInfo && this.userInfo.introduction) {
        return this.userInfo.introduction;
      } else {
        return `no description`;
      }
    },
    contact() {
      if (this.userInfo && this.userInfo.contact) {
        return this.userInfo.contact;
      } else {
        return ``;
      }
    },
  },
  watch: {
    updataUserEventByEdit() {
      this.initEvents();
    },
    "$i18n.locale"(val) {
      if (val == "zh-CN") {
        this.statusNameMap = statusNameMap_zh;
      } else {
        this.statusNameMap = statusNameMap_en;
      }
    },
    userInfo(val) {
      if (this.avatarOptions.findIndex((a) => a.avatar == val.avatar) < 0) {
        this.avatarOptions = this.avatarOptions.slice(0, 3);
        this.avatarOptions.push({
          avatar: val.avatar,
          value: 4,
          label: 4,
        });
      }
    },
  },
  mounted() {
    let query = this.$route.query;
    if (query.init) {
      this.isShowUserInfoEditPanel = true;
      this.$message.success(this.$t("lang.initProfileContact"));
    }
    this.avatarOptions = [
      {
        avatar: imagesCache[1],
        value: 1,
        label: 1,
      },
      {
        avatar: imagesCache[2],
        value: 2,
        label: 2,
      },
      {
        avatar: imagesCache[3],
        value: 3,
        label: 3,
      },
    ];
    if (this.userInfo && this.userInfo.contact) {
      let contact;
      try {
        contact = JSON.parse(this.userInfo.contact);
      } catch (error) {
        contact = {
          contactType: "email",
          contactDetail: "",
        };
      }
      this.profileForm.contact = contact;
    }
    this.profileForm.username = this.username;
    this.profileForm.avatar = this.img;
    this.profileForm.introduction = this.introduction;
    if (this.$i18n.locale == "zh-CN") {
      this.statusNameMap = statusNameMap_zh;
    } else {
      this.statusNameMap = statusNameMap_en;
    }
    this.initEvents();
    this.initMessages();
    this.intervalMessage = window.setInterval(() => {
      this.initMessages();
    }, 300000);
  },
  beforeDestroy() {
    window.clearInterval(this.intervalMessage);
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async handleAvatarChange(file) {
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 0.5;
      if (!isPNG && !isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 200kb!");
        return false;
      } else if (isLt2M && (isPNG || isJPG)) {
        const formData = new FormData();
        this.formData.file = file.raw;
        // this.formData.name = file.name;
        for (const key in this.formData) {
          formData.append(key, this.formData[key]);
        }
        try {
          let res = await uploadImg(formData); //调用上传方法,传递FormData格式的参数
          if (res.code == 200) {
            this.upload_img = res.data.url;
            this.profileForm.avatar = this.upload_img;
          }
        } catch (error) {
          console.log(error);
        }
        this.fileChange = false;
        return isLt2M && (isPNG || isJPG);
      }
    },
    handleCompleteEditProfile() {
      this.updataProfile();
    },
    showUserInfoEditPanel() {
      this.isShowUserInfoEditPanel = true;
    },
    createNewEvent() {
      this.setNewEventDialog(true);
    },
    updataProfile() {
      let vm = this;
      this.$refs["profileForm"].validate((valid) => {
        if (valid) {
          let flag =
            this.profileForm.username != this.username ||
            this.profileForm.avatar != this.img ||
            this.profileForm.introduction != this.introduction ||
            this.profileForm.contact != this.contact;
          let cacheAvatar = this.img;
          if (flag) {
            if (this.profileForm.avatar != this.upload_img) {
              for (let index in imagesCache) {
                if (imagesCache[index] == this.profileForm.avatar) {
                  cacheAvatar = index;
                }
              }
            } else {
              cacheAvatar = this.profileForm.avatar;
            }

            updataUser({
              username: vm.profileForm.username,
              introduction: vm.profileForm.introduction,
              avatar: cacheAvatar,
              contact: JSON.stringify(vm.profileForm.contact),
            })
              .then((res) => {
                if (res.code == 200) {
                  let userinfo = {
                    name: res.data.username,
                    email: this.email,
                    avatar: res.data.avatar,
                    id: res.data.id,
                    introduction: res.data.introduction,
                    isInfoSet: res.data.isInfoSet,
                    token: this.userInfo.token,
                  };

                  this.setUserInfo(userinfo);
                  this.isShowUserInfoEditPanel = false;
                }
                this.$message.success(res.message);
              })
              .catch((e) => {
                console.log(e);
                this.isShowUserInfoEditPanel = false;
              });
          } else {
            this.isShowUserInfoEditPanel = false;
          }
        } else {
          console.log("error submit!!");
          this.isShowUserInfoEditPanel = false;
          return false;
        }
      });
    },
    handleCurrentActivityChange() {
      this.initMessages();
    },
    handleActivitySizeChange() {
      this.initMessages();
    },
    handleCurrentEventChange() {
      this.initEvents();
    },
    handleEventSizeChange() {
      this.initEvents();
    },
    handleIsEventChange(data) {
      if (data) {
        this.initEvents();
      } else {
        this.initMessages();
      }
      this.isEvent = data;
    },
    async initEvents() {
      this.loading = true;
      try {
        let res = await getUserAllEvents({
          page: this.currentEventPage,
          pageSize: this.eventPageSize,
          createdAt: "asc",
        });
        if (res.code == 200) {
          this.eventsTotal = res.data.length;
          this.events = res.data.map((item) => {
            this.preSetEvent(item);
            return {
              id: item.id,
              status: item.status,
              date: [
                new Date(item.startTime).getTime(),
                new Date(item.endTime).getTime(),
              ],
              postUser: item.postUser,
              postUserContact: item.postUserContact,
              receiverContact: item.receiverContact,
              receiveUser: item.receiveUser,
              bounty: item.remuneration,
              content: item.topic,
              description: item.description,
              address: item.location,
              userMessageCount: item.userMessageCount,
              userApplied: item.userApplied,
              applicationCount: item.applicationCount,
            };
          });
          // let filterRes = this.events.filter((e) => !e.isRead);
          // this.eventsNotRead = filterRes.length;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
        if (error.data && error.data.msg) {
          this.$message(error.data.msg);
        }
      }
      this.loading = false;
    },
    async initMessages() {
      this.loading = true;
      try {
        let messages = await getMyMessages({
          page: this.currentActivityPage,
          pageSize: this.activityPageSize,
        });
        if (messages.code == 200) {
          this.messagesNotRead = messages.data.filter((m) => !m.isRead).length;
          this.messagesTotal = messages.data.length;
          this.messages = messages.data.map((item) => {
            if (
              item.fromUser &&
              item.fromUser.avatar &&
              item.fromUser.avatar.length > 0
            ) {
              item.fromUser.avatar = imagesCache[item.fromUser.avatar]
                ? imagesCache[item.fromUser.avatar]
                : item.fromUser.avatar;
              return item;
            } else {
              item.fromUser = {};
              item.fromUser.avatar = avatarDefault;
              return item;
            }
          });
        } else {
          this.$message.error(messages.message);
        }
      } catch (error) {
        console.log(error);
        if (error.data && error.data.msg) {
          this.$message(error.data.msg);
        }
      }
      this.loading = false;
    },
    async editMessageDetail(message) {
      let vm = this;
      if (message && message.relatedEvent && message.relatedEvent.id) {
        vm.setModuleDialog([true, message.relatedEvent.id]);
        if (!message.isRead) {
          try {
            let res = await readMessage({
              messageIds: [message.id],
            });
            if (res.code == 200) {
              vm.$set(message, "isRead", true);
              let messagesNotRead = vm.messages.filter((m) => !m.isRead);
              vm.messagesNotRead = messagesNotRead.length;
            } else {
              vm.$message.error(res.message);
            }
          } catch (error) {
            console.log(error);
            if (error.data && error.data.msg) {
              vm.$message(error.data.msg);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.userinfo-container {
  height: 100%;
  margin-top: 184px;
  padding: 50px 0px;
  width: 100%;
  .userinfo-back {
    position: absolute;
    width: 100%;
    background: $color-bg;
    height: 100%;
    border-radius: 50px;
    margin-top: 54px;
    margin-left: 0;
  }
  .line-ele {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-avatar {
      width: 108px;
      height: 108px;
      border: 4px solid #ffffff;
      background: $color-bg;
      border-radius: 54px;
      box-sizing: border-box;
      filter: drop-shadow(0px 16px 32px rgba(23, 18, 46, 0.16));
      box-shadow: 0px 16px 32px rgba(23, 18, 46, 0.16);
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-left: 50px;
    }
    .btn-position {
      width: 108px;
      height: 108px;
      margin-right: 50px;
      display: inline-flex;
      align-items: center;
      z-index: 1;
    }
    .edit-userinfo-btn {
      width: 150px;
      height: 40px;
      border-radius: 54px;
      box-shadow: 0px 16px 32px rgba(23, 18, 46, 0.16);
      &:hover {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
  .user-description {
    color: $color-text;
    position: absolute;
    margin-top: 40px;
    margin-left: 20px;
    width: calc(100% - 40px);
    overflow: hidden;
    address {
      font-size: 1.4rem;
      font-family: "Helvetica Neue";
      line-height: 24px;
      color: $color-text-gray;
      font-style: normal;
      display: inline-block;
      margin-right: 10px;
    }
    .el-icon-copy-document {
      color: $color-text-gray;
      font-size: 1.4rem;
    }
    .el-icon-copy-document:hover {
      color: $color-text-secondary;
      cursor: pointer;
    }
    .self-introduction {
      font-size: 1.4rem;
      width: 100%;
    }
    .create-event-btn {
      font-size: 2rem;
      background: #f77103;
      border-radius: 20px;
      padding: 10px 20px;
      width: 240px;
      border: none;
      color: $color-primary;
    }
    .create-event-btn:hover {
      color: $color-primary;
      background: $color-orange-dark;
    }
  }
  .list-container {
    position: absolute;
    margin-top: 470px;
    margin-left: 0;
    width: 100%;
    background: #fffae9;
    .close-event {
      position: absolute;
      border: 1px solid $border-color;
      color: $border-color;
      background: $color-bg;
      right: 30px;
      border-radius: 15px;
      font-size: 2rem;
      cursor: pointer;
    }
    .close-event:hover {
      background: $color-orange;
      color: $color-primary;
      border: 1px solid $color-primary;
    }
    .list-header {
      text-align: center;
      width: 100%;
      font-size: 1.6rem;
      .el-badge {
        cursor: pointer;
      }
      .event-btn {
        margin-right: 60px;
      }
    }
    .list-content {
      .event-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: $color-bg;
      }
      .badge-point-wrap {
        display: inline-flex;
        align-items: center;
        height: 36px;
      }
      .badge-point {
        height: 10px;
        width: 10px;
        background-color: #f84848;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        font-size: 12px;
        text-align: center;
      }
      .messages-container {
        ul {
          padding-inline-start: 10px;
          padding-inline-end: 10px;
        }
        .message-item {
          display: flex;
          padding: 20px 0px;
          border-radius: 20px;
          .creator-avatar {
            width: 36px;
            height: 36px;
            margin-left: 20px;
          }
          .message-content-container {
            margin-left: 20px;
            flex: 1;
            p {
              font-size: 1.6rem;
              margin: 0px;
              width: 196px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .learn-more {
              padding: 6px 24px;
              position: static;
              width: 117px;
              height: 36px;
              margin-top: 24px;
              background: #ffffff;
              border: 1px solid #131214;
              box-sizing: border-box;
              border-radius: 99px;
              cursor: pointer;
            }
          }
          .message-time {
            width: 7rem;
            text-align: right;
            margin-right: 1rem;
            font-size: 1.4rem;
          }
        }
        .message-item:hover {
          background: #ffedb0;
        }
      }
    }
    .messages-pagination,
    .event-pagination {
      margin-block-start: 1em;
      margin-block-end: 1em;
      padding-inline-start: 40px;
      margin-top: 50px;
    }
    .event-pagination {
      margin-left: 20px;
    }
  }
}
</style>

<style lang="scss">
@import "@/style/var.scss";
.user-description .el-form-item__label {
  line-height: 40px !important;
  font-size: 1.4rem;
}
.user-description .el-select {
  width: 100px;
  input {
    color: transparent;
  }
}
.user-description .selected-avatar {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 5px;
  z-index: 1;
}
.avatar-type {
  .el-select-dropdown__item {
    height: 100%;
    padding: 10px 20px;
  }
  .select-wrap {
    display: flex;
    align-items: center;
  }
  .select-avatar {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: 1;
    float: left;
  }
}
.list-header .el-badge {
  padding-bottom: 2px;
}
.data-pick.el-date-editor .el-range-input {
  width: 100%;
  font-size: 1.2rem;
}
.userinfo-container ul {
  margin: 0px;
  padding: 0px;
}
</style>
