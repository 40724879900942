
class DateObj {
    constructor(date) {
        this.y = date.getFullYear(); // 年份，注意必须用getFullYear
        this.M = date.getMonth() + 1; // 月份，注意是从0-11this.getMonth() + 1; // 月份，注意是从0-11
        this.d = date.getDate(); // 日期
        this.H = date.getHours(); // 24小时制
        this.m = date.getMinutes(); // 分钟
        this.s = date.getSeconds(); // 秒
    }
} 
const timeStageDivide = { 
    methods: {
        handleTime(time) {
            let date = new Date(time);
            let newDate = new Date();
            let date_obj = new DateObj(date);
            let newDate__obj = new DateObj(newDate);
            if (date_obj.y != newDate__obj.y) {
                return `${date_obj.y}-${date_obj.M}-${date_obj.d}`
            }
            else if (date_obj.M != newDate__obj.M) {
                return `${date_obj.M}-${date_obj.d}`
            }
            else if (date_obj.d != newDate__obj.d) {
                return `${date_obj.M}-${date_obj.d}`
            }
            else if (date_obj.H != newDate__obj.H) {
                return `${Math.abs(newDate__obj.H - date_obj.H)}` + this.$t('lang.hoursBefore')
            }
            else if (date_obj.m != newDate__obj.m) {
                return `${newDate__obj.m - date_obj.m}` + this.$t('lang.minutesBefore')
            }
            else if (date_obj.s != newDate__obj.s) {
                return `${newDate__obj.s - date_obj.s}` + this.$t('lang.secondsBefore')
            }
            else {
                return this.$t('lang.secondsBefore')
            }
        }
    }
}
export default timeStageDivide;